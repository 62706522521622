import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'
import { graphql } from 'src/gql'

const MemberConversationInfoQuery = graphql(`
  query MemberConversationInfo($memberId: Int!) {
    findMember(memberId: $memberId) {
      id
      preferredName
      createdAt
      messagingEnabled
      accountStatus
      benefits {
        id
        contract {
          id
          name
        }
      }
      subscription {
        id
        price {
          name
        }
      }
    }
  }
`)

export const useMemberConversationInfo = (memberId?: string) => {
  const [query, { data }] = useLazyQuery(MemberConversationInfoQuery)

  useEffect(() => {
    if (!memberId) return

    query({ variables: { memberId: +memberId } })
  }, [memberId, query])

  return { ...data?.findMember }
}

import { MoreVertRounded } from '@mui/icons-material'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { UserMessage } from '@sendbird/chat/message'
import {
  sendbirdSelectors,
  useSendbirdStateContext,
} from '@sendbird/uikit-react'
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context'
import { useState } from 'react'

import { ConfirmationDialog } from '../ConfirmationDialog'

interface Props {
  message: UserMessage
}

export const MessageOptions = ({ message }: Props) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const sbContext = useSendbirdStateContext()
  const { currentChannel } = useGroupChannelContext()
  const deleteMessage = sendbirdSelectors.getDeleteMessage(sbContext)

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)

  const handleCloseMenu = () => setAnchorEl(null)

  const handleDelete = async (confirm: boolean) => {
    if (!confirm || !currentChannel) {
      handleCloseMenu()
      setConfirmationOpen(false)
      return
    }

    await deleteMessage(currentChannel, message)
    handleCloseMenu()
  }

  return (
    <>
      <IconButton
        aria-label="Message Options"
        id="options-button"
        size="small"
        aria-controls={menuOpen ? 'options' : undefined}
        aria-expanded={menuOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClickMenu}>
        <MoreVertRounded />
      </IconButton>
      <Menu
        id="options"
        MenuListProps={{ 'aria-labelledby': 'options-button' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}>
        <MenuItem onClick={() => setConfirmationOpen(true)}>
          <Typography variant="body2">Delete</Typography>
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        title="Are you sure you want to delete this message?"
        onClose={handleDelete}
        open={confirmationOpen}
      />
    </>
  )
}

import { UserMessage } from '@sendbird/chat/message'
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context'
import { useEffect, useState } from 'react'

export const useReadReceipts = () => {
  const { messages, currentChannel: selectedChannel } = useGroupChannelContext()
  const appMember = selectedChannel?.members.find(({ userId }) =>
    userId.includes('app_'),
  )
  const [lastMemberRead, setLastMemberRead] = useState<number>()
  const [lastReadMessageId, setLastReadMessageId] = useState<number>()

  useEffect(() => {
    if (!selectedChannel || !appMember) return

    const readStatus = selectedChannel.getReadStatus()
    const newLastMemberRead = readStatus?.[appMember.userId]?.readAt
    if (!newLastMemberRead) return

    const dashboardMessages = messages.filter(
      message =>
        message.messageType === 'user' &&
        (message as UserMessage).sender?.userId.includes('dashboard_'),
    )
    if (dashboardMessages.length < 1) return

    const lastReadMessage = dashboardMessages.find(
      ({ createdAt }) => createdAt >= newLastMemberRead,
    )
    const newLastReadMessageId =
      lastReadMessage?.messageId ??
      dashboardMessages[dashboardMessages.length - 1].messageId

    setLastMemberRead(newLastMemberRead)
    setLastReadMessageId(newLastReadMessageId)
  }, [selectedChannel, messages, appMember])

  return { lastMemberRead, lastReadMessageId }
}
